@import "./../../Scss/shared.scss";
.layout {
  .ant-layout-header,
  .ant-layout-footer {
    color: #000;
    background-color: $blobstationWhite;
    padding: 10px 50px !important;
  }
  .logo-admin {
    float: left;
    width: 200px;
    height: 30px;
    margin: 18px 0px;
    padding: 0px 7px;
  }
  .ant-menu-item {
    padding-left: 20px !important;
  }
  .small-logo {
    float: left;
    width: 40px;
    height: 40px;
    margin: 10px 20px;
    text-align: center;
  }
  .demo-logo-vertical {
    // background: #F7FDFC;
    // background-color: $blobstationWhite;
    .divider {
      border-color: $blobstationBlack;
      margin: 0%;
    }
  }
  .site-layout-content {
    padding: 10px;
  }
  .footer {
    text-align: center;
  }
  .user-profile {
    text-align: center;
    background-color: #2e8096;
    margin: 0px 10px;
    color: $blobstationWhite;
    border-radius: 10px;
    .user-avatar {
      margin: 5px;
    }
    .ant-space-horizontal {
      gap: 4px !important;
    }
    #components-dropdown-demo-arrow .ant-btn {
      margin-right: 18px;
      margin-bottom: 18px;
    }
    .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
      margin-right: 0;
      margin-bottom: 18px;
      margin-left: 18px;
    }
  }
  .ant-layout-sider-trigger .anticon {
    color: #fff !important;
    background-color: #2e8096;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    border: none;
    padding: 10px 21px;
  }
}
@media screen and (max-width: 425px) {
  .logo-admin {
    width: 150px !important;
    height: 25px !important;
  }
  .ant-layout-header {
    padding-inline: 20px !important;
  }
  .layout .site-layout-content {
    padding: 15px;
  }
  .layout .footer-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 320px) {
  .site-layout-content .site-layout-content-box {
    padding: 0px !important;
  }
  .layout .footer-text {
    font-size: 10px;
    text-align: center;
  }
}
