.description-item {
  min-width: 15vh !important;
}
.view-table > .ant-table-cell {
  text-align: center !important;
  width: 30% !important;
}
.download-cv {
  font-size: 18px;
  color: #2e8096;
  font-weight: bolder;
  cursor: pointer;
  margin: 0px 5px;
}
