.view-icon {
  font-size: 18px;
  color: #2e8096;
  font-weight: bolder;
  cursor: pointer;
  margin-right: 13px;
}
.delete-icon {
  font-size: 18px;
  color: #db2929;
  font-weight: bolder;
  cursor: pointer;
  margin-right: 13px;
}
.job-search {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px;
}
.job-pagination {
  margin: 40px 30px;
  display: flex;
  justify-content: flex-end;
}
.job-search .ant-input-search-button:hover {
  border-color: #4096ff;
}
.job-search .ant-btn-primary {
  background-color: #4096ff !important;
}

.job-search .ant-input-search-button:focus {
  border-color: #4096ff !important;
}
.job-search .ant-input-search-button:active {
  border-color: #4096ff !important;
}
