.thank-image-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.thank-image {
  margin: 50px 0px 10px;
  width: 50%;
  height: 50%;
}
.content-group1 {
  display: flex;
  justify-content: center;
}
.content-group2 {
  display: flex;
  justify-content: center;
}
.content-group3 {
  display: flex;
  justify-content: center;
}
.group1-text {
  margin: 0px 0px 30px;
  color: #3196b1;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
.group2-text {
  color: #0a0a0a;
  max-width: 700px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 0px 30px;
}
.group3-text {
  text-decoration: underline;
  text-underline-offset: 30px;
  text-decoration-thickness: 2px;
  text-decoration-color: #3d9fb4;
  margin: 0px 0px 200px;
  color: white;
}
.thank-container {
  user-select: none;
}
@media screen and (max-width: 768px) {
  .thank-image {
    width: 80%;
  }
  .group1-text {
    font-size: 40px;
  }
  .group2-text {
    font-size: 15px;
    margin: 0px 50px;
  }
  .group3-text {
    text-underline-offset: 20px;
  }
}
@media screen and (max-width: 320px) {
  .thank-image {
    width: 80%;
  }
  .group1-text {
    font-size: 36px;
    margin: 60px 0px 30px;
  }
  .group2-text {
    font-size: 15px;
    margin: 0px 40px;
  }
  .group3-text {
    text-underline-offset: 20px;
  }
}
