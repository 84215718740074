.card-container {
  width: 100%;
  height: 200%;
  max-width: 1000px;
  max-height: 80%;
  background: #ffffff;
  box-shadow: 0px 14px 34px 0px #cbe4ed;
  border: 1.45292px solid #e6e6e6;
  border-radius: 5px;
}
.header-container {
  padding: 0px 70px;
}
.header-heading {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.header-subheading {
  color: #2e8096;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 2px;
}
.heading-content {
  color: #606060;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
#header-border {
  width: 100%;
  height: 1px;
  background: #d7d7d7;
}
.content-container {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .header-container { 
    padding: 0px 30px;
  }
  .header-heading {
    font-size: 30px;
    line-height: 36px;
  }
  .header-subheading {
    font-size: 20px;
  }
  .heading-content {
    font-size: 14px;
    line-height: 26px;
    text-align: left;
  }
}
@media screen and (max-width: 320px) {
  .header-container {
    padding: 0px 30px;
  }
  .header-heading {
    font-size: 25px;
    line-height: 36px;
  }
  .header-subheading {
    font-size: 17px;
  }
  .heading-content {
    font-size: 12px;
    line-height: 26px;
    text-align: left;
  }
}
