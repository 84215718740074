@import "./../../Scss/shared.scss";
.layout-admin {
  .ant-layout-footer {
    color: $blobstationWhite;
    background-color: #1b1c1d;
    display: flex;
    justify-content: center;
  }
  .navbar-admin {
    display: flex;
    align-items: center;
  }
  .ant-menu-title-content-admin {
    font-size: 16px;
  }
  .site-layout-content-admin {
    padding: 30px;

    .site-layout-content-box-admin {
      min-height: 600px;
      padding: 24px;
    }
  }
  .footer-admin {
    text-align: center;
    width: 100%;
    background-color: $blobstationBlack;
    color: $blobstationWhite;
    margin: 10px 0px;
    font-size: 18px;
  }
  .footer-text-admin {
    margin: -9px 0px -9px;
    font-size: 18px;
  }
  .ant-menu-item-selected {
    color: $blobstationBlack !important;
  }
  .ant-menu-horizontal {
    border-bottom: 0px !important;
    width: 100%;
  }
}
.header-group-admin {
  width: auto;
  background: #ffffff;
  height: 50px;
}
.navbar-group-admin {
  display: flex;
  height: 100%;
  padding: 0px 20px;
}
.logo-group-admin {
  display: flex;
  align-items: center;
  margin: 0px 100px;
  height: 100%;
}
.logo-group-admin a {
  display: flex;
  text-decoration: none;
  height: 22px;
}
@media screen and (max-width: 768px) {
  .layout-admin .ant-layout-footer {
    padding: 20px 0px !important;
  }
}
@media screen and (max-width: 425px) {
  .layout-admin .site-layout-content-admin {
    padding: 10px;
  }
  .layout-admin .footer-text-admin {
    font-size: 12px;
  }
}
@media screen and (max-width: 375px) {
  .site-layout-content-admin .site-layout-content-box-admin {
    padding: 0px !important;
  }
  .layout-admin .footer-text-admin {
    font-size: 10px;
    text-align: center;
  }
}
