.login-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.login_card-container {
  width: 100%;
  height: 200%;
  max-width: 1000px;
  max-height: 80%;
  background: #ffffff;
  box-shadow: 0px 14px 34px 0px #cbe4ed;
  border: 1.45292px solid #e6e6e6;
  border-radius: 5px;
  margin: 170px 0px;
}
.login_header-container {
  margin: 25px 30px;
}
#login_header-border {
  height: 1px;
  background: #d7d7d7;
}
.login_content-container {
  width: 100%;
}
.blob-image {
  width: 30%;
  height: 30%;
}
.login-heading {
  color: #2e8096;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.common-input > .ant-input {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 0;
}
.common-input > .ant-input-affix-wrapper {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.ant-form-item-label > label {
  color: #000 !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding: 0px 5px;
}
.common-input > .ant-input:hover .ant-input:focus {
  border-color: #2e8096 !important;
}
.common-input .ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #b3d2df !important;
}
.common-input > .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3d8097 !important;
  border-color: #3d8097 !important;
}
.common-input > .ant-checkbox-checked:after {
  border: 2px solid #3d8097 !important;
}

.common-input .ant-checkbox-inner::after {
  width: 6px !important;
  height: 11px !important;
}
.ant-input-affix-wrapper > input.ant-input {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 0;
}
.submit-button {
  border-radius: 9px;
  background: #3d8097;
  width: 100%;
  height: 50px;
  margin: 30px 0px;
}
.button-group .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}

.submit-text {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
@media screen and (max-width: 768px) {
  .center {
    margin: 40px 30px;
  }
  .submit-button {
    width: 50%;
  }
  .ant-col-offset-8 {
    margin-inline-start: 80px !important;
  }
  .submit-text {
    color: #fff;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 375px) {
  .submit-button {
    width: 50%;
  }
  .ant-col-offset-8 {
    margin-inline-start: 50px !important;
  }
}
