@import "./../../Scss/shared.scss";
.layout {
  .ant-layout-footer {
    color: $blobstationWhite;
    background-color: #1b1c1d;
    display: flex;
    justify-content: center;
  }
  .navbar {
    display: flex;
    align-items: center;
  }
  .ant-menu-title-content {
    font-size: 16px;
  }

  .header-group {
    width: 100%;
    background: #ffffff;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 3;
  }
  .navbar-group {
    width: 100%;
    height: 100%;
    max-width: 1500px;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    grid-template-rows: 100%;
    grid-template-columns: 240px 1fr 200px;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-self: auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: grid;
    position: sticky;
    top: 0;
  }
  .logo-group {
    display: flex;
    align-items: center;
    margin: 0px 100px 0px 25px;
    height: 100%;
    justify-content: center;
  }
  .logo-group a {
    display: flex;
    text-decoration: none;
    height: 22px;
  }
  .menu-group {
    color: #0b0c0d;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    height: 100%;
    margin: 0px 150px;
  }
  .navbar-menu-button {
    display: flex;
    justify-content: flex-end;
  }
  .site-layout-content {
    padding: 30px;

    .site-layout-content-box {
      min-height: 600px;
      padding: 24px;
    }
  }
  .footer {
    text-align: center;
    width: 100%;
    color: $blobstationWhite;
    font-size: 18px;
  }
  .footer-text {
    margin: -9px 0px -9px;
    font-size: 18px;
    width: 1800px;
    max-width: 1800px;
  }
}
.nav-button {
  height: 46px;
  color: #fff !important;
  background-color: #2e8096;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  padding: 0px 30px;
}
.nav-button > a {
  color: #fff !important;
  text-decoration: none;
}
@media screen and (max-width: 1440px) {
  .logo-group {
    margin: 0px 100px 0px 0px !important;
  }
  .menu-group {
    margin: 0px 0px !important;
  }
  .navbar-group {
    grid-template-columns: 410px 1fr 200px !important;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-group {
    grid-template-columns: 300px 1fr 200px !important;
  }
}
@media screen and (max-width: 768px) {
  .navbar-group {
    grid-template-columns: 266px 1fr 0px !important;
  }
  .menu-group {
    padding: 0px 0px !important;
  }
  .ant-layout-footer {
    padding: 0px 0px !important;
  }
  .header-group {
    padding: 0px 0px !important;
  }
  .navbar-menu-button {
    display: none !important;
  }
}
@media screen and (max-width: 425px) {
  .ant-layout-header {
    padding-inline: 20px !important;
  }
  .layout .site-layout-content {
    padding: 10px;
  }
  .layout .footer-text {
    font-size: 12px;
  }
  .header-group {
    padding: 0px 0px !important;
  }
  .nav-button {
    padding: 0px 110px !important;
  }
}
