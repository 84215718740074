.icon {
  width: 40px;
  height: 40px;
  background-color: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px;
  margin: 10px 6px;
}
.submenu {
  display: block;
  margin: 17px 0px;
  padding: 10px 0px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  z-index: 3;
  position: absolute;
}
.submenu a {
  color: #121313 !important;
  text-decoration: none;
  display: flex !important;
  padding: 0px 10px;
  align-items: center;
  margin: 0px 15px 0px 0px;
}
.submenu a:active {
  color: #2e8096 !important;
}
.submenu a:hover {
  color: #2e8096 !important;
}
