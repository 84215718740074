.main-container {
  padding: 120px 42px;
  max-width: 1403px;
  margin-left: auto;
  padding-bottom: 32px;
  margin-right: auto;
}
.footer-logo {
  max-width: 250px;
  height: auto;
}
.column-text {
  width: auto;
  text-align: left;
  line-height: 32px;
  font-size: 18px;
  margin-top: 12px;
  display: flex;
  justify-content: left;
}
.add-column-text{
  font-size: 16px;
  opacity: 0.9;
}
.icon-container > a {
  color: white;
  text-decoration: none;
}
.icon-container {
  display: flex;
  justify-content: flex-start;
}
.icon-footer {
  margin: 0px 15px 0px 0px;
  width: 26px;
  height: 26px;
}
.icon-container > a:hover {
  color: white;
  text-decoration: none;
}
.column-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: white;
}
.column-heading1 {
  font-style: normal;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
}
.link-group > li > a {
  color: white;
  width: 100%;
  font-weight: 500;
  font-family: sans-serif;
  line-height: 32px;
  font-size: 16px;
  text-decoration: none;
}
.link-group > li > a:hover {
  color: #2e8096;
  text-decoration: none;
}
.link-group > li > a:active {
  color: #2e8096;
  text-decoration: none;
}
.link-group > li {
  display: flex;
}

.link-group{
  grid-row-gap: 16px;
  width: 90%;
  transform-origin: 50% 0;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.info-container {
  text-align: left;
  max-width: 400px;
  grid-row-gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  /* margin-right: 22px; */
}

.info-container-sub{
  grid-row-gap: 24px;
}
.icon-add {
  width: 48px;
  height: 48px;
  margin: 0px 0px -13px -55px;
}
.add-group {
  margin-bottom: 80px;
}
.address-text {
  margin-top: 20px;
}
.footer-copyright {
  margin-top: 100px;
  width: 100%;
  opacity: .8;
  font-size: 14px;
  display: flex;
}
.link-wrapper {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 64px;
}
.link-col {
  max-width: none !important;
  flex: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.address-container {
  margin: 0px 0px 50px;
  display: flex;
  grid-column-gap: 24px;
}
@media screen and (max-width: 1440px) {
  .column-text {
    width: 340px;
  }
 
  
}

@media screen and (max-width: 1200px) {
  .custom-row{
    flex-flow: inherit !important;
  }
  .column-text{
    width: 180px !important;
  }
  .link-wrapper{
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .info-container {
    padding: 0px 40px;
  }
  .link-wrapper {
    display: flex;
    flex-direction: column;
    width: 223px;
    margin: 40px 0px;
    gap: 40px;
  }
  .column-text {
    width: auto ;
    font-size: 16px;
  }
  .main-container {
    padding: 100px 0px;
    padding-bottom: 32px;
  }
  .footer-logo {
    max-width: 170px;
    height: auto;
  }
  .link-col {
    padding-right: 12px !important;
  }
  .footer-copyright {
    margin-left: 0% !important;
    padding-left: 40px;
  }
  .column-heading {
    font-size: 24px !important;
  }
  .column-heading1 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 425px) {
  .footer-copyright {
    margin-top: 50px;
  }
  .custom-row{
    flex-flow:wrap !important;
  }
  .address-container{
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
  }
  .link-wrapper {
    width: 375px;
    margin: 38px 0px;
    gap: 40px;
    padding: 40px 10px;
  }
  .info-container-sub{
   padding: 0 !important;
  }
  .column-text {
    width: auto !important;
    font-size: 18.5px;
    margin-bottom: 20px;
  }
  .custom-column-tex{
    text-align: justify;
  }
  .main-container {
    padding: 100px 0px;
    padding-bottom: 32px;
  }
  .sub-main-container{
    padding: 0px 44px;
  }
  .footer-logo {
    max-width: 332px;
    height: auto;
    /* margin: 20px 0px; */
  }
  .icon-add {
    margin: 0px 10px -13px 0px;
  }
 
  .link-col {
    width: 100%;
    padding-right: 0px !important;
  }
  .info-container {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 320px) {
  .main-container {
    padding: 40px 0px;
  }
  .column-text {
    font-size: 16px;
    width: 200px;
    margin: 0px 30px 20px;
  }
  .link-wrapper {
    width: 218px;
    padding: 0px;
  }
  .footer-copyright {
    font-size: 12px;
  }
  .column-heading1 {
    font-size: 23px;
  }
  .column-heading {
    font-size: 23px;
  }
  .address-container {
    margin: 0px;
  }
}
