.main-navbar-container {
  width: 100%;
  display: flex;
  height: 100%;
}
.link-container {
  display: flex;
  align-items: center;
}
.link-container > li > a {
  color: black;
  text-decoration: none;
}
.link-container > li {
  display: flex;
  line-height: 40px;
  font-size: 15px;
  margin: 10px 20px;
  align-items: center;
}
.link-container > li > a:hover {
  color: #2e8096 !important;
  text-decoration: none;
}
.link-container > li > a:focus {
  color: #2e8096 !important;
  text-decoration: none;
}
.service-list:hover {
  color: #2e8096 !important;
  text-decoration: none;
}
.button-container {
  display: flex;
  align-items: flex-end;
}
.hamburger {
  display: none;
}
.navbar-icon {
  font-size: 20px;
  color: black;
}
.button-container-navbar {
  display: none;
}

.service-list .anticon svg {
  display: flex;
  margin: 3px 5px 0px;
}

.outline-icon {
  font-size: 12px;
}
.submenu-group-active {
  display: flex;
  justify-content: center;
}
.submenu-group {
  display: none;
}
.service-list {
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .link-container > li {
    display: flex;
    line-height: 40px;
    font-size: 13px !important;
    margin: 10px 13px;
    align-items: center;
  }
}
@media screen and (max-width: 1024px) {
  .link-container > li {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 768px) {
  .link-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: white;
    position: absolute;
    display: none;
    margin-top: 48px;
    left: 0;
    z-index: 4;
    transform: translateY(0px) translateX(0px);
    transition: transform 400ms ease 0s;
    overflow: hidden;
  }
  .link-container.active {
    display: flex;
  }
  .link-container > li {
    padding: 5px 0;
  }
  .link-container > li > a {
    font-size: 25px;
  }
  .hamburger {
    display: flex;
    z-index: 5;
    align-items: center;
    font-size: 18px;
  }
  .button-container-navbar {
    display: flex;
  }
  .outline-icon {
    font-size: 20px;
  }
  .service-list .span-list {
    font-size: 25px;
  }
  .nav-button {
    padding: 0px 280px;
    margin: 0px 0px 30px;
  }
  .main-navbar-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
