.commonForm-container {
  width: 100%;
  padding: 30px 130px;
}
.input-container {
  width: 100%;
}
.input-container .ant-col {
  width: 100%;
  max-width: 100% !important;
}
.input-row {
  gap: 0px;
}
.upload-icon {
  width: 22px;
  height: 22px;
  margin: 10px 15px;
}
.upload-button {
  display: flex;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
}
.upload-text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 3px 0px;
}
.validation-container {
  color: red;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 3px 0px;
}
.validation-logo {
  margin: 0px 5px;
}
.ant-btn:hover {
  border: 1px solid #d2d2d2 !important;
  color: #d2d2d2 !important;
}
.ant-btn:active {
  border: 1px solid #d2d2d2 !important;
  color: #d2d2d2 !important;
  box-shadow: none !important;
}
.ant-input:focus {
  box-shadow: none !important;
}
.checkbox-group .ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
  border: 1px solid #b3d2df !important;
}
.checkbox-group > .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3d8097 !important;
  border-color: #3d8097 !important;
}
.checkbox-group > .ant-checkbox-checked:after {
  border: 2px solid #3d8097 !important;
}
.checkbox-group > .ant-checkbox-checked:after {
  border: none !important;
}
.checkbox-group .ant-checkbox-inner::after {
  width: 6.714286px !important;
  height: 16.142857px !important;
}

.checkbox-group .ant-checkbox .ant-checkbox-inner:after{
  top: 42%;
}

.resume-container {
  width: 100%;
}
.checkbox-container {
  width: 100%;
}
.resume-container .ant-upload-select {
  width: 100% !important;
  margin: 10px 0px;
}
.checkbox-container .ant-checkbox {
  margin: 0px 10px 0px 0px;
}
.checkbox-content {
  color: #255465;
  text-align: center;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.commonForm-submit {
  border-radius: 9px;
  background: #3d8097;
  width: 100%;
  height: 50px;
  margin: 30px 0px;
}
.submit-span {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.captcha-container {
  margin: 20px 0px;
}
.content {
  padding: 50px;
  border-radius: 4px;
}
.ant-spin-text {
  font-size: 20px !important;
}
.input {
  width: 100%;
  height: 100%;
  padding: 20px 10px;
}
.common-input-container {
  width: 100%;
}
.common-input-container > .ant-input::placeholder {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding: 0px 5px;
}
.common-input-container > .ant-input:hover {
  border-color: #d9d9d9 !important ;
}
.common-input-container > .ant-input:focus {
  border-color: #d9d9d9 !important ;
  box-shadow: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.common-input-container > .ant-input {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .commonForm-container {
    padding: 0px 0px;
  }
  .input::placeholder {
    color: #000;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 450 !important;
    line-height: 36px !important;
    padding: 0px 5px !important;
  }
  .ant-input {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
  .submit-span {
    color: #fff;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
  .resume-container {
    padding: 0px 30px;
  }
  .input-container {
    display: grid;
    gap: 10px;
    padding: 0px 30px;
  }
  .input::placeholder {
    font-size: 17px !important;
  }
  .checkbox-content {
    font-size: 13px !important;
    line-height: 18px;
  }
  .checkbox-group .ant-checkbox-inner {
    margin: 0px 0px 0px 25px;
    width: 24px !important;
    height: 24px !important;
  }
  .submit-container {
    padding: 0px 30px;
  }
  .upload-icon {
    margin: 10px 0px;
  }
  .recaptcha {
    margin: 0px 15px;
  }
}
@media screen and (max-width: 425px) {
  .input-container .ant-row {
    display: grid !important;
  }

  .input::placeholder {
    font-size: 18px !important;
  }
  .upload-text {
    font-size: 18px;
    margin: 6px 0px;
  }
  .rc-anchor-normal {
    margin: 0px 0px 0px 20px;
    height: 74px;
    width: 242px;
  }
}
